exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-alumni-tsx": () => import("./../../../src/pages/alumni.tsx" /* webpackChunkName: "component---src-pages-alumni-tsx" */),
  "component---src-pages-coaches-tsx": () => import("./../../../src/pages/coaches.tsx" /* webpackChunkName: "component---src-pages-coaches-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-players-tsx": () => import("./../../../src/pages/players.tsx" /* webpackChunkName: "component---src-pages-players-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-tournaments-tsx": () => import("./../../../src/pages/tournaments.tsx" /* webpackChunkName: "component---src-pages-tournaments-tsx" */)
}

